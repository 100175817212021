<template>
  <div class="c-app flex-row align-items-center">
    <CContainer v-show="!loading">
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="text-center py-5 d-md-down-none">
              <CCardBody>
                <h2>ログイン</h2>
                <p>登録した情報を入力してください。</p>
                <CInput placeholder="メールアドレス" autocomplete="username email" v-model="email" type="email">
                  <template #prepend-content><CIcon name="cil-user" /></template>
                </CInput>
                <CInput placeholder="パスワード" type="password" autocomplete="curent-password" v-model="password">
                  <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                </CInput>
                <div class="caution"></div>
                <div>{{ errorMessage }}</div>
                <CButton @click="login" class="login-button">ログイン</CButton>
              </CCardBody>
            </CCard>
            <CCard text-color="white" class="text-center py-5 d-md-down-none register">
              <CCardBody class="">
                <h2>新規登録</h2>
                <p>初めての方はこちらから登録してください。</p>
                <CInput placeholder="メールアドレス" autocomplete="username email" v-model="newEmail" type="email">
                  <template #prepend-content><CIcon name="cil-user" /></template>
                </CInput>
                <CInput placeholder="パスワード" type="password" autocomplete="curent-password" v-model="newPassword">
                  <template #prepend-content><CIcon name="cil-lock-locked" /></template>
                </CInput>
                <div class="caution">※6文字以上の英数字を設定してください。</div>
                <div>{{ errorMessage }}</div>
                <CButton @click="signUp" class="make-account-button" variant="outline"> アカウントを作成 </CButton>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <Loading v-show="loading" />
  </div>
</template>

<script>
import firebase from "firebase";
import router from "../../router/index";

import Loading from "@/views/components/Loading";
export default {
  name: "Login",
  components: {
    Loading,
  },
  data() {
    return {
      email: "",
      password: "",
      newEmail: "",
      newPassword: "",
      errorMessage: "",
      loading: true,
    };
  },
  async created() {
    await this.userCheck();
  },
  mounted() {},
  methods: {
    // googleLogin() {
    //   this.$store.dispatch("googleLogin");
    // },
    login() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
    },
    signUp() {
      this.$store.dispatch("signUp", {
        email: this.newEmail,
        password: this.newPassword,
      });
    },
    async userCheck() {
      await firebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
          router.push("/dashboard");
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        } else {
          this.loading = false;
          // console.log("自動ログイン失敗");
        }
      });
    },
  },
};
</script>

<style scoped>
.img {
  height: 80px;
}
.make-account-button {
  color: #fafafa;
  border: 1px solid #fafafa;
  font-weight: bold;
}
.make-account-button:hover {
  color: #ff866b;
  background-color: #fafafa;
  font-weight: bold;
}
.login-button {
  background-color: #ff866b;
  color: #fafafa;
  font-weight: bold;
}
.login-button:hover {
  color: #ff866b;
  border: 1px solid #ff866b;
  background-color: #fafafa;
  font-weight: bold;
}
.register {
  background-color: #ff866b;
}
.horizontal-row {
  position: relative;
  /* margin-bottom: 18px; */
  margin-top: 17px;
}
.Row:before,
.Row:after {
  display: table;
  content: "";
}
.Row:after {
  clear: both;
}
.horizontalText {
  width: 100%;
  text-align: center;
  position: relative;
  color: #ababab;
  font-size: 14px;
  z-index: 1;
  overflow: hidden;
}
.register-side {
  color: #fafafa;
}
.horizontalText:before,
.horizontalText:after {
  width: 50%;
  top: 50%;
  overflow: hidden;
  height: 1px;
  background-color: #d0d0d0;
  content: "\a0";
  position: absolute;
}
.register-side:before,
.register-side:after {
  background-color: #fafafa;
}
.horizontalText:before {
  margin-left: -52%;
  /* text-align: right; */
}
.horizontalText:after {
  margin-left: 2%;
}
.auth-google {
  height: 40px;
  border-radius: 7px;
  border: solid 1px #d0d0d0;
  cursor: pointer;
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  text-align: center;
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.auth-google-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-google-text {
  max-width: 280px;
  font-size: 15px;
  color: #6a6a6a;
  display: inline-block;
  position: relative;
  padding-left: 6px;
}
.caution {
  padding-bottom: 10px;
  height: 31px;
}
</style>
